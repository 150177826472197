import { projects } from "../constants/projects";
import { useEffect } from "react";
import { Logos } from "./Logos";
import useWindowSize from "../hooks/WindowSize";
import { parseTextWithLinks } from "../utils/parser";
export default function Projects() {
  const { width } = useWindowSize();

  useEffect(() => {
    const handleScroll = () => {
      const projectSections = document.querySelectorAll(".project-section");
      const scrollTop = window.scrollY;
      const halfHeight = window.innerHeight / 2;
      const offset = 64;

      projectSections.forEach((section, index) => {
        const sectionTop = section.getBoundingClientRect().top + scrollTop;
        const sectionBottom = section.getBoundingClientRect().bottom + scrollTop;

        if (
          sectionTop > scrollTop &&
          sectionTop < scrollTop + halfHeight - offset
        ) {
          const projectNumber = document.querySelectorAll(".project-number");

          projectNumber.forEach((projectNumber) => {
            projectNumber.style.transform = `translateY(-${
              index * 116 + index
            }%)`;
          });
        }
        if (sectionBottom > scrollTop + offset && sectionBottom < scrollTop + halfHeight) {
          const projectNumber = document.querySelectorAll(".project-number");
    
          projectNumber.forEach((projectNumber) => {
            projectNumber.style.transform = `translateY(-${index * 116 + index}%)`;
          });
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className="bg-purpsdle-500 pt-16 md:mt-40 mt-16 sm:mb-8 relative z-10"
      id="projects"
    >
      <div className="grid-section bg-ered-500 flex flex-col items-center justify-center pb-24">
        <div className="xl:px-32 md:px-16 xs:px-8 px-4 w-full">
          <div className="uppercase xs:text-[4rem] text-[3.5rem] md:font-normal lg:mb-36 mb-16 leading-none tracking-[2px]">
            <div className="">
              {width > 790 ? "Featured Projects" : "Projects"}
            </div>
          </div>
          <div className="flex lg:gap-20 md:gap-4 w-full">
            <div className="lg:flex font-grotesk text-[10rem] font-light leading-[13rem] sticky -ml-3 top-0 bg-orannge-800 min-w-[230px] h-[205px] overflow-hidden hidden">
              <span className="one">0</span>
              <span className={` delay-200 flex flex-col gap-9`}>
                {projects.map((project, index) => (
                  <div
                    className="relative z-50 project-number flex"
                    key={index}
                  >
                    {index + 1}
                    <span
                      className={`h-[16px] w-[16px] ${project.color} rounded-full self-end mb-12`}
                    ></span>
                  </div>
                ))}
              </span>
            </div>
            <div className="flex gap-24 flex-col">
              {projects.map((project, index) => (
                <div
                  className={`project-section flex gap-12 ${project.title
                    .toLowerCase()
                    .replace(/\s/g, "")}`}
                  key={index}
                >
                  <div className="flex flex-col gap-1">
                    
                    <div className="text-[1.7rem] font-medium mt-10">
                      {project.title}
                    </div>
                    <div className="mb-5 tracking-wide leading-relaxed text-[15px] md:text-[1rem]">
                      {parseTextWithLinks(
                        project.description,
                        "text-rose-500 font-[500]"
                      )}
                    </div>
                    <div className="flex gap-6 flex-wrap">
                      {project.technologies.map((tech, index) => (
                        <img
                          src={Logos[tech.toLowerCase()]}
                          alt="tech"
                          className="h-10"
                          key={index}
                        />
                      ))}
                    </div>
                    <img
                      src={project.image}
                      alt={project.title}
                      className="lg:mt-8 mt-6"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
