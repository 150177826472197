export const work = [
    {
        title: "Software Engineer (Generative AI)",
        company: "Alfabolt",
        start: "Nov 24",
        end: "Present",
        type: "Full-time",
        tasks: [
            "Understand Generative AI (GenAI) and Retrieval-Augmented Generation (RAG) to develop AI-driven agentic solutions tailored for business environments.",
            "Explore emerging LLM tools, including LangChain, LangGraph, and AI workflow automation tools like N8N, Flowise, GumLoop & more.",
            "Develop a Proof of Concept (PoC) for [Elba Security](https://www.elba.security), a security platform for streamlining security & compliance workflows through automated integrations.",
            "Developing an AI-driven workflow for Elba to automate app integrations, eliminating manual API research & coding. This is expected to cut integration time by up to 70%."
        ],
    },
    {
        title: "Front-end Developer",
        company: "Alfabolt",
        start: "Jun 24",
        end: "Nov 24",
        type: "Full-time",
        tasks: [
            "Worked as a front-end engineer with a UK-based startup [Reimag.in](https://www.reimag.in), a data platform that provides companies & investors with performance insights & reporting.",
            "Integrated backend APIs into NextJs app and translated Figma designs into pixel-perfect UI.",
            "Implemented reporting frameworks into the platform, including ESG Data Convergence Initiative (EDCI), Global Reporting Initiative (GRI), Sustainable Development Goals (SDGs), 2x Challenge and more.",
            "Developed and optimized data visualizations, including graphs, charts, tables, dashboards, progress bars, providing intuitive insights for users.",
            "Actively participated in Agile development practices, contributing to daily standups, sprint planning, and timely feature delivery.",
            "Previously, led the front-end web app development of an internal management tool, from design to implementation."
        ],
    },
    {
        title: "Team Lead (Front-end)",
        company: "Asteramind",
        start: "Dec 23",
        end: "Present",
        type: "Part-time",
        tasks: [
            "Lead a team of three developers, orchestrating task assignments, conducting daily one-to-one meetings and providing guidance.",
            "Translate Figma designs into responsive web pages with pixel-perfect precision.",
            "Implement timely solutions for client’s requirements (new features and bugs), following agile development methodology.",
            "Conduct code reviews and provide actionable feedback to consistently deliver high-quality and efficient code."
        ],
        link: "shipfro.com",
    },
    {
        title: "Software Design Engineer",
        company: "xFlow Research Inc.",
        start: "Jun 22",
        end: "July 23",
        type: "Part-time",
        tasks: [
            "Contribute to the development of Linux Foundation [SONiC](https://sonicfoundation.dev), a network operating system.",
            "Authored a [design spec](https://github.com/sonic-net/SONiC/pull/1298) (4000+ words) for Multiple Spanning Tree Protocol in SONiC consisting of architecture, sequence diagrams, UX design, database schema & test plan.",
            "Presented & successfully defended the design to 40+ SONiC community members consisting of reviewers from Microsoft, Nvidia, Google, Broadcom, and others. [Link to recording](https://zoom.us/rec/share/3XxqJ20eag07iK0x7T_4YqBq6jhbyWXGjpk72AvHL35peWSOXZ04w_ZQjGlwXJbM.0DPyXu03R_bS0Gry)",
            "Comprehend the SONiC implementation of network protocols by studying 10+ GitHub repositories.",
        ],

    },
    {
        title: "Research Assistant",
        company: "HPC Lab, NUST",
        start: "Jun 22",
        end: "Nov 22",
        type: "Internship",
        tasks: [
            "Conducted research on Electric Vehicles for the development of sustainable transportation solutions and developed a framework to study the EVs adoption over infrastructure.",
            "Designed an algorithm for optimization of EV charging reservations over the provided infrastructure.",
            "Simulated using AnyLogic to evaluate the proposed solution and demonstrate its effectiveness.",
            "Publication: [UrbanEVSim: Open-Source Electric Vehicle Mobility and Charging Simulation Platform](https://ieeexplore.ieee.org/document/10410367)"
        ],
    }
]