import Images from "../components/Images";

export const projects = [
    {
        title: "Reimag.in",
        description: "[Reimag.in](https://www.reimag.in) is your copilot for sustainability performance and management. An enterprise data platform that provides companies and investors with performance insights and reporting, streamlining stakeholder collaboration, visibility and compliance.",
        image: Images.Reimagin,
        technologies: ["NextJs", "Typescript", "CSS"],
        link: "/",
        github: "/",
        color: "bg-green-600"
    },
    {
        title: "Shipfro",
        description: "Developed the front-end of [shipfro.com](https://shipfro.com) . Shipfro delivers a seamless courier aggregation experience, connecting their clients to a network of trusted carriers for cost-effective and reliable shipping services.",
        image: Images.Shipfro,
        technologies: ["React", "MUI", "Cypress"],
        color: "bg-rose-500"
    },
    {
        title: "CodeHive",
        description: "A web-based programming assessments platform. Directly attempt your programming assignments in a tailored VS Code instance in browser with zero configuration. Automatically grade assignments via test cases and conduct programming exams in a restricted IDE environment. [Learn more](https://drive.google.com/file/d/1J7C5x0G69lfR_4qLAd1LkMctSpQGvZE3/view?usp=sharing)",
        image: Images.CodeHive,
        technologies: ["NextJs", "Typescript", "MongoDB", "Docker", "Terraform", "Cypress", "Tailwindcss"],
        link: "/",
        github: "/",
        color: "bg-purple-500"
    },
    {
        title: "Workaholics",
        description: "Workholics is a freelancing platform connecting skilled individuals with clients seeking professional services.  It features gig postings, a review system for building reputations, and milestones for tracking project progression.",
        technologies: ["php", "laravel", "HTML", "CSS", "javascript"],
        image: Images.Workaholics,
        link: "/",
        github: "/",
        color: "bg-indigo-600"
    },
    // {
    //     title: "Astros",
    //     description: "The game centers on a space expedition in a spacecraft facing various challenges. Players must shoot down comets and asteroids, avoid black holes, and collect boosters. Gaining points allows progression to higher levels, each with increasing difficulty.",
    //     image: Images.Astros,
    //     technologies: ["Python", "Pygame"],
    //     link: "/",
    //     github: "/",
    //     color: "bg-green-500"
    // },
]